import 'nprogress/nprogress.css';

import Router from 'next/router';

import(/* webpackChunkName: "nprogress" */ 'nprogress').then((NProgress) => {
  NProgress.configure({ showSpinner: false });
  Router.events.on('routeChangeStart', () => NProgress.start());
  Router.events.on('routeChangeComplete', () => NProgress.done());
  Router.events.on('routeChangeError', () => NProgress.done());
});

const PageProgressBar = () => {
  return null;
};

export default PageProgressBar;
